import { Link } from 'gatsby'
import logo from '../../images/logo.svg'
import IconButton from '../IconButton'
import menuIcon from '../../images/menu.svg'
import xIcon from '../../images/x.svg'
import clsx from 'clsx'
import * as React from 'react'
import { Fragment, useState } from 'react'
import { isValidURL } from '../../utilities'

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const handleMenuClick = () => {
    setMenuOpen(!menuOpen)
  }
  return (
    <Fragment>
      <div>
        <div className='px-6 md:px-12 xl:px-20 mx-auto h-20 flex items-center justify-between'>
          <Link to='/'>
            <img src={logo} alt='logo' className='h-16' />
          </Link>
          <ul className='hidden md:flex text-lg'>
            <NavItem to='/'>Home</NavItem>
            <NavItem to='https://insolvency.substack.com/'>Blog</NavItem>
            {/* <NavItem to='/blog'>Blog</NavItem> */}
            <NavItem to='/project'>Project</NavItem>
            <NavItem to='/business'>Business</NavItem>
          </ul>
          <IconButton className='md:hidden' onClick={handleMenuClick}>
            <img src={menuIcon} alt='menu' />
          </IconButton>
        </div>
      </div>
      {menuOpen && (
        <div
          className='absolute bg-white left-0 right-0 top-0 bottom-0 flex justify-center items-center
        '
        >
          <IconButton
            onClick={handleMenuClick}
            className='absolute top-6 right-6'
          >
            <img src={xIcon} alt='close' />
          </IconButton>
          <ul className='text-center'>
            <NavItem to='/' mobile>
              Home
            </NavItem>
            <NavItem to='https://insolvency.substack.com/' mobile>
              Blog
            </NavItem>
            <NavItem to='/project' mobile>
              Project
            </NavItem>
            <NavItem to='/business' mobile>
              Business
            </NavItem>
          </ul>
        </div>
      )}
    </Fragment>
  )
}

const NavItem = ({ to, children, mobile = null }) => {
  return (
    <li
      className={clsx(
        'font-display font-medium hover:text-gray-600 transition-colors',
        mobile ? 'mb-4 last:mb-0 text-2xl' : 'mr-4 last:mr-0'
      )}
    >
      {isValidURL(to) ? (
        <a href={to} target='_blank' rel='noreferrer'>
          {children}
        </a>
      ) : (
        <Link to={to}>{children}</Link>
      )}
    </li>
  )
}

export default Header
