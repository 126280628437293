import * as React from 'react'
import clsx from 'clsx'

export default function IconButton({ onClick, className, children }) {
  return (
    <button
      onClick={onClick}
      className={clsx('w-10 h-10 flex justify-center items-center', className)}
    >
      {children}
    </button>
  )
}
