import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout/Layout'
import Footer from '../components/Layout/Footer'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <div>
        <div className='flex flex-col prose max-w-screen-md mx-auto mt-8 px-8 md:mt-16'>
          <p className='font-display font-medium mb-16'>
            <Link to='/project'>Back to Project</Link>
          </p>
          <h1 className='font-display self-center text-xl md:text-2xl'>
            {frontmatter.title}
          </h1>
          <p className='mt-0 self-center'>{frontmatter.date}</p>
          <div className='mt-4' dangerouslySetInnerHTML={{ __html: html }} />
        </div>
        <Footer />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        type
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`
