// footer only for blog page
import * as React from "react"

export default function Footer() {
  return (
    <div className="mx-6 md:mx-12 xl:mx-20 mt-20 h-20 flex justify-center items-center border-t">
      <p className="text-sm text-gray-600">© 2022 ruanjian.io</p>
    </div>
  )
}
